import React from "react";
import { Link } from "gatsby";
import "./NewsFeature.scss";
import dayjs from "dayjs";
import { Waypoint } from "react-waypoint";

const NewsFeature = ({ node }) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <Link
        to={`/article/${node.uid}/`}
        className={`news-feature waypoint ${active ? "waypoint-active" : ""}`}
        style={{ backgroundImage: `url('${node.data.hero_image.fluid.src}')` }}
      >
        <div className="news-feature__content align-center">
          <div className="row">
            <div className="col col-md-6 col-md-offset-3">
              <h6 className="heading heading--xxs uppercase text--medium">
                {dayjs(node.data.date).format("MMM YYYY").toUpperCase()}
              </h6>
              <h3 class="heading heading--l text--black">
                {node.data.title.text}
              </h3>
              <div
                className="text--l text--medium"
                dangerouslySetInnerHTML={{ __html: node.data.summary.html }}
              ></div>
              {node.data.categories.document && (
                <span className="tag">
                  {node.data.categories.document.data.title}
                </span>
              )}
            </div>
          </div>
        </div>
      </Link>
    </Waypoint>
  );
};
export default NewsFeature;
