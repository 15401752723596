import React from "react";
import Dropdown from "react-dropdown";
import "./ListNavigation.scss";

const ListNavigation = ({
  title,
  currentCategory,
  categories,
  onChange,
  onSearch,
}) => {
  const [term, setTerm] = React.useState("");

  let listCategories;
  let currentIndex = 0;

  if (categories) {
    listCategories = categories.map((item, index) => {
      if (currentCategory === item.uid) {
        currentIndex = index + 1;
      }

      return {
        label: item.data.title,
        value: item.uid,
      };
    });
  } else {
    listCategories = [];
  }

  const handleSearch = (e) => {
    e.preventDefault();
    if (onSearch) {
      onSearch(term);
    }
  };

  const options = [
    { label: "All", label: "All", label: "All", label: "All", value: "" },
    ...listCategories,
  ];

  return (
    <div className="list-navigation">
      <div className="container">
        <div className="list-navigation__inner">
          <div className="list-navigation__filter">
            <Dropdown
              className="Dropdown"
              options={options}
              value={options[currentIndex]}
              onChange={onChange}
            />
          </div>
          <div className="list-navigation__title">
            <h1>{title}</h1>
          </div>
          <div className="list-navigation__search">
            <form className="search" onSubmit={handleSearch}>
              <div className="form-control">
                <input
                  type="text"
                  value={term}
                  placeholder="Search"
                  onChange={(e) => setTerm(e.target.value)}
                />
                <img src={require("./../assets/svg/search.svg")} alt="Search" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListNavigation;
