import React from "react";
import { Link } from "gatsby";
import dayjs from "dayjs";
import { Waypoint } from "react-waypoint";

import "./NewsItem.scss";
const NewsItem = ({ size, related, node }) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  const linkTo = node.uid === null ? "/article" : `/article/${node.uid}/`;

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`news-item size-${size} ${
          related ? "related-item" : ""
        } waypoint ${active ? "waypoint-active" : ""}`}
      >
        <Link to={linkTo} className="news-item__link">
          <div className="news-item__media">
            {node.data.thumb && node.data.thumb.fluid && (
              <img src={node.data.thumb.fluid.src} alt="Thumbnail" />
            )}
          </div>
          <div className="news-item__content">
            <div className="news-item__content-inner">
              <div className="caption">
                {node.data.categories.document && (
                  <span className="tag">
                    {node.data.categories.document.data.title}
                  </span>
                )}
                <span className="tag-label">
                  {dayjs(node.data.date).format("MMM YYYY").toUpperCase()}
                </span>
              </div>
              <h3 className="heading heading--l text--black">
                {node.data.title.text}
              </h3>
              {related !== true && (
                <div
                  className="text text--md"
                  dangerouslySetInnerHTML={{ __html: node.data.summary.html }}
                ></div>
              )}
            </div>
          </div>
        </Link>
      </div>
    </Waypoint>
  );
};
export default NewsItem;
