import React from "react";
import { Link } from "gatsby";
import "./ReadOnCTA.scss";
import { Waypoint } from "react-waypoint";

const ReadOnCTA = ({ title, items }) => {
  const SHAPES = {
    process: require("./../assets/svg/icons/process-color.svg"),
    service: require("./../assets/svg/icons/services-color.svg"),
    team: require("./../assets/svg/icons/team-color.svg"),
    think: require("./../assets/svg/icons/think-color.svg"),
    work: require("./../assets/svg/icons/work-color.svg"),
    contact: require("./../assets/svg/icons/contact-color.svg"),
  };

  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div className={`readon waypoint ${active ? "waypoint-active" : ""}`}>
        <div className="box">
          <div className="container container-medium">
            <div className="heading-group seperator align-center">
              <h4 className="heading heading--xs uppercase text--medium">
                {title.text}
              </h4>
            </div>
            <div className="row">
              {items &&
                items.map((item, index) => {
                  return (
                    <div
                      key={`item_${index}`}
                      className="col col-md-offset-1 col-md-4 col-sm-6"
                    >
                      <div className="readon-item">
                        <div className="image-wrapper">
                          <img src={SHAPES[item.item_icon]} alt="Icon" />
                        </div>
                        <hr />
                        <h3 className="heading text--medium heading--l">
                          {item.item_title.text}
                        </h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.item_content.html,
                          }}
                        ></div>
                        <Link
                          to={"/" + item.item_link.uid + "/"}
                          className="link-arrow"
                        >
                          {item.item_link_title}
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </Waypoint>
  );
};

export default ReadOnCTA;
