import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import Prismic from "prismic-javascript";

import "./../components/Think.scss";

import Layout from "../components/layout";
import SEO from "../components/seo";

import ListNavigation from "../components/ListNavigation";
import NewsItem from "../components/NewsItem";
import NewsFeature from "../components/NewsFeature";
import ReadOnCTA from "../components/ReadOnCTA";
import { navigate } from "gatsby";
import client from "./../lib/Api";

export const query = graphql`
  query($cat: String!) {
    allPrismicArticleCategory {
      nodes {
        data {
          title
        }
        uid
      }
    }
    allPrismicArticle(
      filter: { data: { categories: { uid: { glob: $cat } } } }
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            summary {
              html
            }
            author
            date
            hero_image {
              alt
              fluid(maxWidth: 1800) {
                src
              }
            }
            thumb {
              url
              alt
              fluid(maxWidth: 1800) {
                src
              }
            }
            categories {
              document {
                ... on PrismicArticleCategory {
                  id
                  data {
                    title
                  }
                }
              }
            }
            title {
              text
            }
          }
        }
      }
    }
    allPrismicNewsPage {
      nodes {
        data {
          meta_description
          meta_title
          canonical {
            uid
            type
          }
          read_on_items {
            item_icon
            item_link {
              uid
            }
            item_link_title
            item_content: item_summary {
              html
            }
            item_title {
              text
            }
          }
          read_on_title {
            text
          }
          title {
            text
          }
        }
        uid
      }
    }
  }
`;

const getResults = (articles, term = "", keys = []) => {
  // console.log(articles, term, keys)

  const filtered =
    term !== ""
      ? articles.filter((item) => {
          return keys.includes(item.node.uid);
        })
      : articles.filter(() => true);

  return [
    filtered.filter((item, index) => index < 6),
    filtered.filter((item, index) => index === 6),
    filtered.filter((item, index) => index > 6),
  ];
};

const BlogPage = (props) => {
  const { data, location, pageContext } = props;
  const categories = data.allPrismicArticleCategory.nodes;
  const articles = data.allPrismicArticle.edges;
  const pageData = data.allPrismicNewsPage.nodes[0].data;
  const meta = {
    descripition: pageData.meta_description,
    title: pageData.meta_title,
    uid: pageData.canonical.uid,
    pageType: pageData.canonical.type,
  };

  const [loading, setLoading] = useState(false);
  // const [keys, setKeys] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState(null);

  const handleSearch = (term) => {
    setLoading(true);
    setSearchTerm(term);
  };

  useEffect(() => {
    if (searchTerm === "") {
      return;
    }
    client
      .query([
        Prismic.Predicates.at("document.type", "article"),
        Prismic.Predicates.fulltext("document", searchTerm),
      ])
      .then((res) => {
        setLoading(false);
        const data = res.results;
        const keys =
          data.length === 0
            ? []
            : data.map((item) => {
                return item.uid;
              });
        setResults(getResults(articles, searchTerm, keys));
      });
  }, [searchTerm]);

  useEffect(() => {
    setResults(getResults(articles, "", []));
  }, []);

  return (
    <Layout location={location}>
      <SEO meta={meta} description={meta.descripition} title={meta.title} uid={meta.uid} pageType={meta.pageType} />
      <div className="blog page">
        <img
          className={"think-icon"}
          src={require("./../assets/svg/icons/think-color.svg")}
        />
        <ListNavigation
          title={"Blog"}
          currentCategory={pageContext.cat === "*" ? "" : pageContext.cat}
          categories={categories}
          onSearch={handleSearch}
          onChange={(e) => {
            navigate(`/blog/${e.value}`);
          }}
        />
        {loading && (
          <div>
            <div className="container text-center margin-verticle">
              <h2>Loading...</h2>
            </div>
          </div>
        )}
        {loading === false && results && (
          <div>
            {results[0].length === 0 && (
              <div>
                <div className="container text-center margin-verticle">
                  <h2>
                    Sorry, there are no articles available with this search or
                    filter.
                  </h2>
                </div>
              </div>
            )}

            <div className={`${results[0].length > 1 ? "container" : ""}`}>
              <div className="blog-items">
                {results[0].map((item, index) => {
                  let size = "small";
                  if (index === 0) {
                    size = "large";
                  } else if (index < 3) {
                    size = "med";
                  }
                  return <NewsItem size={size} {...item} />;
                })}
              </div>
            </div>

            <div className={`${results[1].length > 1 ? "container" : ""}`}>
              <div className="blog-items">
                {results[1].map((item, index) => {
                  return <NewsFeature {...item} />;
                })}
              </div>
            </div>

            <div className={`${results[2].length > 1 ? "container" : ""}`}>
              <div className="blog-items">
                {results[2].map((item, index) => {
                  return <NewsItem size={"small"} {...item} />;
                })}
              </div>
            </div>
          </div>
        )}
        <ReadOnCTA
          title={pageData.read_on_title}
          items={pageData.read_on_items}
        />
      </div>
    </Layout>
  );
};

BlogPage.query = query;

export default withPreview(BlogPage);
